@mixin style_normal{
  @media all and (max-width: 1200px) {
    @content
  }
}

@mixin style_tablet{
  @media all and (max-width: 991.5px) {
    @content
  }
}

@mixin style_mobile{
  @media all and (max-width: 767.5px){
    @content
  }
}

@mixin style_mobile_sm{
  @media all and (max-width: 379.5px){
    @content
  }
}