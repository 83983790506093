html, body{
  height: 100%;
  background: white;
  position: relative;
  font-family: "lato", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.maps_google{
  iframe{
    pointer-events: none;
  }
}
