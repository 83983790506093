.cont_detail_product{
  width: 100%;
  background: white;
  &::after{
    content: '';
    display: block;
    clear: both;
  }

  .pnl_product{
    margin: 60px auto;
    max-width: $size_container_web;
    @include style_tablet{
      margin: 0;
    }

    &::after{
      content: '';
      display: block;
      clear: both;
    }

    .text{

      max-width: 800px;
      width: 60%;
      float: left;
      padding: 0 25px;
      line-height: 23px;
      position: relative;
      font-size: 16px;

      @include style_tablet{
        width: 100% !important;
        margin-top: 30px;
        padding: 0 20px;
      }

      .cont_titule{
        background: darkred;
        margin-bottom: 15px;
        padding-left: 15px;

        .wrapper_titule{
          background: darkred;
          padding: 10px;
          display: inline-block;

          .titule{
            color: white;
            font-size: 20px;
            padding: 5px 5px;
          }
        }

      }


    }

    .wrapper_image{
      float: left;
      width: 40%;
      text-align: center;
      padding: 60px;
      background: #f6f6f6;

      @include style_tablet{
        width: 100% !important;
      }

      img{
        height: 400px;
      }
    }

    .pnl_con_image{
      padding: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }

  }

}