.container_ref{
  width: 100%;

  .titule{
    color: darkred;
    margin-bottom: 30px;
  }

  .wrapper_list{
    max-width: $size_container_web;
    margin: auto;
    width: 90%;
    padding: 20px;


    .list_referencias{
      margin: 0;
      padding: 0;
      list-style: none;

      &:after{
        content: ' ';
        display: block;
        clear: both;
      }

      li{
        float: left;
        width: 25%;
        padding: 5px;

        @include style_tablet{
          width: 33.3%;
        }

        @include style_mobile{
          width: 100%;
          margin-bottom: 30px;
        }

        &:first-child{
          margin-left: 0;
        }

        .cont_item{
          position: relative;

          img{
            width: 100%;
          }

          .wrapper_text{
            background: rgba(0, 0, 0, 0.41);
            opacity: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            top: 0;
            transition: all .5s;

            .titule{
              text-align: center;
              color: white;
              display: block;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              font-size: 18px;
              transition: all .5s;
            }

          }
        }


        &:hover{
          box-shadow: 2px 2px 12px black;

          .wrapper_text{
            opacity: 0;

            .titule{
              top: 23%;
            }

          }
        }



      }

    }

  }
}