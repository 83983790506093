
.container_app_header {
  position: relative;
  width: 100%;

  .wrapper_menu_rapido {
    background: black;
    position: relative;
    margin: auto;
    @include style_mobile{
      display: none;
    }

    .container_menu_rapido {
      max-width: $size_container_web;
      position: relative;
      background: black;
      height: 30px;
      padding: 7px 0;
      color: white;
      font-size: 12px;
      margin: auto;
      @include style_tablet{
        height: 50px;
      }

      .wrapper_info_alert {
        position: absolute;
        left: 0;
        padding-left: 10px;

        > ul {
          margin: 0;
          padding: 0;
          list-style: none;
          > li {
            float: left;
            margin-right: 40px;
          }
        }
      }

      .wrapper_menu_social {
        position: absolute;
        right: 0;
        @include style_tablet{
          top: 28px;
          left: 10px;
        }

        > ul {
          list-style: none;
          padding: 0;
          margin: 0;
          > li {
            float: left;
            margin-right: 15px;
            i {
              padding: 0 7px 0 0;
            }

            a {
              color: white;
            }
          }
        }
      }
    }

  }

  .wrapper_menu {
    border-bottom: solid thin #b0b0b0;
    box-shadow: 0 1px 9px #a9a9a9;
    background: #ddd;

    .container_menu {
      max-width: $size_container_web;
      position: relative;
      width: 100%;
      margin: auto;
      transition: all .4s;
      @include style_tablet{
        position: static;
      }

      &::after {
        content: "";
        clear: both;
        display: table;
      }

      .wrapper_logo {
        float: left;
        padding: 10px;
        transition: all .4s;
        @include style_tablet{
          padding: 5px;
        }
        img {
          height: 50px;
          transition: all .4s;
        }
      }

      .wrapper_icon_menu{
        position: absolute;
        right: 30px;
        top: 70px;
        display: none;
        @include style_tablet{
          display: block;
        }

        @include style_mobile{
          top: 20px;
        }

      }

      .menu {
        float: right;
        list-style-position: outside;
        list-style: none;
        padding: 20px 0 0 0;
        margin: 0;
        transition: all .4s;


        @include style_tablet{
          background: #dddddd;
          position: absolute;
          padding: 0;
          width: 320px;
          left: 0;
          top: 0;
          height: 100vh;
          overflow-y: scroll;
          z-index: 100;
          float: none;
          display: none;
        }

        &.show_mobile{
          display: block;
        }

        .wrapper_header_menu{
          display: none;
          position: relative;
          padding: 0 20px;

          @include style_tablet{
            display: block;
          }

          .btn_close{
            background: darkred;
            position: absolute;
            z-index: 10;
            top: -8px;
            right: 20px;
            padding: 5px 10px;
            border-radius: 10px;
            color: white;
            cursor: pointer;
          }

        }

        > li {
          display: inline-block;
          margin-left: 10px;
          @include style_tablet{
            margin: 0;
            padding: 0;
            width: 100%;
            border-bottom: solid thin #b7b7b7;
          }

          > a {
            display: block;
            color: black;
            text-decoration: none;
            transition: all .4s;
            padding: 10px 4px;
            text-transform: uppercase;
            font-size: 0.9em;
            @include style_tablet{
              padding: 10px 20px;
            }

            &:hover {
              cursor: pointer;
              border-bottom: solid 4px dimgrey;
              @include style_tablet{
                border: none;
              }
            }
          }

          &.active {
            > a {
              color: darkred;
              border-bottom: solid 4px darkred;
              @include style_tablet{
                border: none;
              }
            }
          }

          &.item_submenu {
            @include style_tablet{
              position: relative;
            }

            i{
              position: absolute;
              right: 10px;
              padding: 10px;
              cursor: pointer;
              display: none;
              @include style_tablet{
                display: block;
              }
            }

            .cont_submenu{
              display: none;
              background: #dfdfdf;
              padding: 30px 50px;
              position: absolute;
              z-index: 3;
              width: 100%;
              left: 0;
              transition: all .4s;

              @include style_tablet{
                display: none;
                padding: 0;
                position: relative;
                background: #f2f2f2;
              }

              .colum_submenu{
                float: left;
                padding: 0 10px;
                margin-right: 90px;
                border-left: dotted thin darkred;
                @include style_tablet{
                  float: none;
                }


                &:after{
                  content: ' ';
                  display: block;
                  clear: both;
                }

                .submenu_productos{
                  list-style: none;
                  padding: 0;
                  @include style_tablet{
                    float: none;
                    border: none;
                    margin: 0;
                    padding-left: 30px;
                  }

                  li{
                    padding: 6px 0;
                    a{
                      color: darkred;
                      text-decoration: none;
                      &:hover{
                        color: black;
                      }

                    }
                  }
                }

              }

            }

            &:hover{
              .cont_submenu{
               display: block;
                @include style_tablet{
                  display: none;
                }
              }
            }

          }

        }

      }

    }

    &.active {
      position: fixed;
      top: 0;
      z-index: 20;
      background: rgba(215, 215, 215, 0.96);
      margin: auto;
      width: 100%;

      .wrapper_logo {
        padding: 10px 0 10px 30px;
        img {
          height: 40px;
        }
      }

      .wrapper_icon_menu{
        position: absolute;
        right: 30px;
        top: 25px;
      }
    }

  }

}