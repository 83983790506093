.container_page_prod_ascensores{
  width: 100%;

  .parallax_prod_ascensores{
    background: url('../../../../../storage/app/media/productos/main/ascensores_header.jpg');
    height: 500px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .caption{
      position: absolute;
      background: rgba(36, 36, 36, 0.78);
      width: 100%;
      bottom: 0;
      color: white;
      padding: 20px 30px;
      font-size: 25px;
    }
  }

  .container_text{
    max-width: $size_container_web;
    background: white;
    margin: auto;
    padding: 20px 15px;
    line-height: 26px;

    .row{
      margin: 0;
    }
  }

  .container_list{

    .row{
      margin: 0;
    }

    .wrapper_text_titule{
      max-width: $size_container_web;
      margin: auto;
      padding: 20px;
    }

    .wrapper_pnl_productos {
      width: 100%;
      .pnl_productos {
        max-width: $size_container_web;
        background: white;
        margin: auto;
        padding: 0 0 30px 0;

        .titule {
          color: darkred;
        }

        .wrapper_list {
          ul {
            list-style: none;
            @include style_mobile{
              padding: 20px;
            }

            .item {
              text-align: center;
              background: whitesmoke;
              overflow: hidden;
              cursor: pointer;
              position: relative;
              width: 23%;
              float: left;
              margin-right: 10px;
              margin-bottom: 10px;
              @include style_tablet{
                width: 31%;
                margin-right: 7px;
              }

              @include style_mobile{
                width: 100%;
              }

              img {
                height: 200px;
                transition: all 2s ease;
              }

              .content_item {
                opacity: 1;
                position: absolute;
                top: 0;
                width: 100%;
                text-align: center;
                transition: all .5s ease;
                color: white;

                .wrapper_titule {
                  background: darkred;
                  padding: 10px 3px;
                  text-transform: uppercase;
                  font-size: 13px;
                }

              }

              &:hover {

                img {
                  -moz-transform: scale(1.1);
                  -webkit-transform: scale(1.1);
                  transform: scale(1.1);
                }

                .content_item {
                  opacity: 1;
                  height: 100%;
                }
              }

            }
          }

        }

      }
    }
  }

}