.cont_pasantia{
  width: 100%;

  .parallax_pasantia{
    background: url('../../../../../storage/app/media/carrera_profesional/pasantia/parallax_pasantia.jpg');
    height: 400px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .wrapper_pasantia{
    max-width: $size_container_web;
    margin: auto;
    line-height: 25px;
    padding: 15px;
    @include style_mobile{
      padding: 15px 20px;
    }



    .row{
      margin: 0;
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-bottom: 30px;
    }

    .titule_1{
      color: darkred;
    }

    .pnl_info{
      background: #ededed;
      padding: 15px;
      @include style_mobile{
        padding: 0;
        margin: 10px -15px;
      }

      .pnl_titule{
        font-weight: bold;
      }

    }

  }
}