.cont_graduados{
  width: 100%;

  .parallax_graduados{
    background: url('../../../../../storage/app/media/carrera_profesional/graduados/parallax_graduados.jpg');
    height: 400px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .wrapper_graduados{
    max-width: $size_container_web;
    margin: auto;
    line-height: 25px;
    padding: 15px;
    @include style_mobile{
      padding: 0 20px;
    }



    .row{
      margin: 0;
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-bottom: 30px;
    }

  }
}