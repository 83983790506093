.container_page_inicio {
  width: 100%;

  .wrapper_slider {
    width: 100%;

    .container_slider {
      //max-width: $size_container_web;
      margin: auto;

      #carousel_landing {
        .carousel-inner {
          .item {

            @include style_mobile{
              height: 305px !important;
            }

            img {
              width: 100%;
              @include style_mobile{
                height: 100%;
              }
            }

            .caption_position_left {
              background: rgba(36, 36, 36, 0.78);
              right: initial;
              bottom: 0;
              left: 0;
              padding: 10px 30px;
              color: white;
              width: 100%;
              img{
                width: 250px;
              }
            }

            .caption_position_center {
              background: rgba(36, 36, 36, 0.78);
              right: initial;
              bottom: 45%;
              left: 50%;
              margin-left: -22.5%;
              padding: 30px;
              color: white;
            }

          }
        }
      }

    }
  }

  .wrapper_pnl_nosotros {
    width: 100%;
    background: lightgrey;
    .pnl_nosotros {
      max-width: $size_container_web;
      margin: auto;
      padding: 40px 50px;
      @include style_mobile{
        padding: 40px 20px;
      }

      .row {
        margin: 0;
      }

      .titule {
        color: darkred;
      }

      .container_list {
        max-width: 1660px;
        padding-top: 25px;

        .list_nosotros {
          list-style: none;
          margin: 0;
          padding: 0;
          > li {
            float: left;
            width: 33.3%;
            text-align: center;

            @include style_mobile{
              width: 100%;
              margin-bottom: 30px;
            }

            .container_info {
              border-right: dotted 1px darkred;
              height: 100%;
              padding: 5px 15px;
              @include style_mobile{
                border: none;
              }

              .container_img {
                width: 100px;
                height: 100px;
                margin: auto;
                background: white;
                border-radius: 70px;

                i{
                  color: darkred;
                  font-size: 40px;
                  margin: 30px 0;
                }

              }

            }

            &:last-child {
              .container_info {
                border: none;
              }
            }
          }
        }

      }
    }
  }

  .cont_pnl_slogan{
    width: 100%;
    background: darkred;

    .wrapper_pnl_slogan{
      max-width: $size_container_web;
      text-align: center;
      padding: 50px 30px;
      margin:auto;

      span{
        font-size: 22px;
        color: white;
      }
    }

  }

  .wrapper_pnl_brands {
    width: 100%;

    .pnl_brands {
      background: white;
      margin: auto;
      padding: 30px 50px;
      max-width: $size_container_web;

      .titule {
        color: darkred;
      }

      .container_carousel {
        padding-top: 30px;

        .item {
          text-align: center;

          img {
            height: 60px;
          }
        }
      }

    }
  }

  .wrapper_pnl_productos {
    width: 100%;
    .pnl_productos {
      max-width: $size_container_web;
      background: white;
      margin: auto;
      padding: 30px 0;

      .titule {
        color: darkred;
      }

      .wrapper_list {
        ul {
          list-style: none;

          @include style_mobile{
            padding: 20px;
          }

          .item {
            text-align: center;
            background: whitesmoke;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            width: 23%;
            float: left;
            margin-right: 10px;
            margin-bottom: 10px;
            @include style_tablet{
              width: 31%;
              margin-right: 7px;
            }

            @include style_mobile{
              width: 100%;
            }

            img {
              height: 200px;
              transition: all 2s ease;
            }

            .content_item {
              opacity: 1;
              position: absolute;
              top: 0;
              width: 100%;
              text-align: center;
              transition: all .5s ease;
              color: white;

              .wrapper_titule {
                background: darkred;
                padding: 10px 3px;
                text-transform: uppercase;
                font-size: 13px;
              }

            }

            &:hover {

              img {
                -moz-transform: scale(1.1);
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .content_item {
                opacity: 1;
                height: 100%;
              }
            }

          }
        }

      }

    }
  }

  .wrapper_pnl_servicios {
    width: 100%;
    .pnl_servicios {
      background: white;
      margin: auto;
      padding: 30px 0;
      max-width: $size_container_web;

      .titule {
        color: darkred;
      }

      .wrapper_list {
        ul {
          list-style: none;
          text-align: center;
          @include style_mobile{
            padding: 25px;
          }

          .item {
            float: left;
            width: 30%;
            margin: 0 1%;
            @include style_mobile{
              width: 100%;
              margin-bottom: 30px;
            }

            .container_panel {
              background: #f8f8f8;
              padding: 25px 15px;
              border: solid thin #e5e5e5;
              border-radius: 3px;

              .wrapper_titule {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 25px;
              }

              .wrapper_texto {
                line-height: 25px;
                margin-bottom: 25px;
                @include style_tablet{
                  min-height: 200px;
                }

                @include style_mobile{
                  min-height: inherit;
                }

              }

              .btn_leer {
                background: darkred;
                padding: 15px 40px;
                display: inline-block;
                color: white;
                text-decoration: none;
              }

            }

          }
        }
      }

    }
  }

}