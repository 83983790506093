.container_page_productos_1{
  width: 100%;
  background: white;
  &::after{
    content: '';
    display: block;
    clear: both;
  }

  .pnl_asc_con_maq{
    margin: auto;
    max-width: 1600px;
    border-bottom: solid thin lightgrey;

    &::after{
      content: '';
      display: block;
      clear: both;
    }

    .text{

      max-width: 800px;
      width: 60%;
      float: left;
      padding: 150px 80px;
      line-height: 23px;
      position: relative;
      font-size: 14px;


      .cont_titule{
        background: darkred;
        margin-bottom: 40px;
        padding-left: 15px;

        .titule{
          color: darkred;
          background: white;
          display: inline-block;
          font-size: 16px;
          padding: 0 10px;
        }
      }


    }

    .wrapper_image{
      float: left;
      width: 40%;
      text-align: center;
      padding: 60px;
      background: #f6f6f6;
      img{
        height: 400px;
      }
    }

    .pnl_con_image{
      padding: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }

  }




}