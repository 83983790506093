.container_contacts{

  .row{
    margin: 0;
  }

  .wrapper_titule{
    margin: 40px 0 10px 0;
    .titule{
      color: darkred;
    }

  }


  .pnl_contactos{
    max-width: 100%;
    margin-bottom: 50px;

    &::after{
      content: ' ';
      display: block;
      clear: both;
    }


    .wrapper_contactos{
      width: 900px;
      margin: auto;

      @include style_tablet{
        width: 100%;
        padding: 0 20px;
      }

      .wrapper_titule{
        text-align: center;
        .titule{
          font-size: 20px;
          color: darkred;
        }

      }

      .wrapper_list{
        .list_contacts{
          margin: 0;
          padding: 0;
          list-style: none;

          li{
            width: 48%;
            height: 185px;
            background: #f2f2f2;
            float: left;
            margin: 1%;
            padding: 8px 10px;
            border: solid thin lightgray;
            @include style_mobile{
              width: 220px;
              display: block;
              height: auto;
              text-align: center;
              margin: auto;
              margin-top: 20px;
              float: none;
            }

            img{
              width: 150px;
              float: left;

              @include style_tablet{
                width: 145px;
              }

              @include style_mobile{
                float: none;
                display: block;
                width: 120px;
                margin: auto;
              }
            }

            .cont_info{
              padding: 17px;
              padding-left: 160px;
              font-size: 16px;

              @include style_tablet{
                padding: 25px 0 0 155px;
              }

              @include style_mobile{
                padding: 5px;
                font-size: 16px;
                width: 100%;
                display: block;
              }

              span{
                display: block;
                &.titule{
                  margin-bottom: 10px;
                  text-align: center;
                  font-weight: bold;
                  font-size: 16px;
                }

                &.email{
                  font-size: 12px;
                }

              }

              a{
                color: black;
                padding: 10px 0 10px 0;
                i{
                  color: darkred;
                }
              }

            }

            &.tipo_2{
              text-align: center;

              img{
                float: none;
                width: 75%;
                display: block;
                margin: auto;
                padding: 10px 0;
              }
              .cont_info{
                text-align: center;
                padding: 10px 0;
              }
            }

          }

        }

      }

    }



  }


  .cont_data_contacts{
    width: 100%;
    .wrapper_data_contacts{
      max-width: $size_container_web;
      margin: auto;
      .wrapper_list_details{
        padding: 40px 50px;
        @include style_tablet{
          padding: 0;
        }

        .titule{
          font-size: 20px;
          color: darkred;
          margin-left: 65px;
          @include style_tablet{
            margin: 0;
            display: block;
            text-align: center;
          }
        }

        > ul{
          list-style: none;
          margin-top: 25px;
          @include style_tablet{
            width: 100%;
            padding: 0 20px;
          }
          @include style_mobile{
            padding: 0;
          }

          > li {
            margin-bottom: 25px;

            .container_text{
              display: block;

              > i {
                float: left;
                padding: 20px 20px;
                font-size: 24px;
                color: darkred;
                @include style_mobile{
                  padding: 20px 15px;
                }
              }

              span{
                float: left;
                color: #646464;
              }

              .text_one_line{
                padding: 20px 0 ;
              }

              &:after {
                content: "";
                clear: both;
                display: table;
              }

            }

          }
        }

      }

    }
  }

  .cont_form{
    border-left: dashed lightgrey;
    @include style_tablet{
      border: none;
    }

    .wrapper_form{
      padding: 40px 30px;
      @include style_tablet{
        padding: 40px 20px;
      }

      .form{
        .titule{
          font-size: 20px;
          color: darkred;
          @include style_tablet{
            display: block;
            text-align: center;
          }
        }

        ul{
          list-style: none;
          margin: 20px 0 0 0;
          padding: 0;

          li{
            margin-bottom: 15px;
            label{
              display: block;
            }

            input, textarea{
              display: block;
              width: 100%;
              border: solid thin lightgray;
              padding: 8px;
              outline-color: lightgrey;
              outline-style: solid;
              outline-width: 1px;

            }

            #enviar_consulta{
              background: darkred;
              width: 200px;
              color: white;
              border: none;
              padding: 10px;
              transition: background .4s;

              &:hover{
                background: #7c0505;
              }

            }

          }
        }

      }

    }
  }


  .cont_maps_google{
    width: 100%;
  }

}