.container_job{
  width: 100%;


  .row{
    margin: 0;
  }

  .wrapper_job{
    max-width: $size_container_web;
    margin: auto;
    padding: 50px 20px;
    line-height: 24px;
    text-align: justify;

    .btn_back{
      display: inline-block;
      position: relative;
      left: 50%;
      margin-left: -12.5%;
      margin-top: 50px;

      a{
        border: solid 2px darkred;
        color: darkred;
        text-decoration: none;
        padding: 10px 50px;
        display: block;
        transition: all .4s;

        i{
          padding-right: 5px;
        }

        &:hover{
          background: darkred;
          color: whitesmoke;
        }
      }
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-bottom: 30px;
    }

    .referencia{
      background: #ededed;
      display: inline-block;
      padding: 15px;

      span{
        display: block;

        &:first-child{
          font-weight: bold;
        }

      }
    }



  }
}

.conta_puesto_trabajo{
  width: 100%;

  .row{
    margin: 0;
  }

  .parallax_puestos_trabajo{
    background: url('../../../../../storage/app/media/carrera_profesional/puesto_de_trabajo/parallax_puesto_trabajo.jpg');
    height: 400px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }


  .wrapper_text{
    max-width: $size_container_web;
    margin: auto;
    text-align: justify;
    line-height: 26px;
    @include style_mobile{
      padding: 0 20px;
    }


    .row{
      margin: 0;
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-top: 50px;
      text-transform: uppercase;
    }

    .subtitule{
      color: darkred;
    }

    .text_otro{
      font-size: 18px;
      font-weight: bold;
    }

  }

  .wrapper_table{
    max-width: $size_container_web;
    margin: auto;
    padding: 20px 30px;
    @include style_mobile{
      padding: 0;
    }

    .table_jobs{
      width: 100%;

      tr{

        &:nth-child(odd){
          background: #ededed;
        }

        &:nth-child(even){
          background: lightgrey;
        }

        &.header{
          background: darkred;
          color: white;
          font-weight: bold;
          text-transform: uppercase;
        }

      }

      td{
        padding: 15px;
        font-size: 16px;
        @include style_mobile{
          padding: 10px 12px;
        }

        a{
          color: black;
        }

        &:nth-child(2),
        &:nth-child(3){
          text-align: center;
        }

      }
    }

  }
}


