html, body {
  height: 100%;
  background: white;
  position: relative;
  font-family: "lato", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.maps_google iframe {
  pointer-events: none;
}

.container_app_header {
  position: relative;
  width: 100%;
}

.container_app_header .wrapper_menu_rapido {
  background: black;
  position: relative;
  margin: auto;
}

@media all and (max-width: 767.5px) {
  .container_app_header .wrapper_menu_rapido {
    display: none;
  }
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido {
  max-width: 1020px;
  position: relative;
  background: black;
  height: 30px;
  padding: 7px 0;
  color: white;
  font-size: 12px;
  margin: auto;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu_rapido .container_menu_rapido {
    height: 50px;
  }
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_info_alert {
  position: absolute;
  left: 0;
  padding-left: 10px;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_info_alert > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_info_alert > ul > li {
  float: left;
  margin-right: 40px;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social {
  position: absolute;
  right: 0;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social {
    top: 28px;
    left: 10px;
  }
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social > ul > li {
  float: left;
  margin-right: 15px;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social > ul > li i {
  padding: 0 7px 0 0;
}

.container_app_header .wrapper_menu_rapido .container_menu_rapido .wrapper_menu_social > ul > li a {
  color: white;
}

.container_app_header .wrapper_menu {
  border-bottom: solid thin #b0b0b0;
  box-shadow: 0 1px 9px #a9a9a9;
  background: #ddd;
}

.container_app_header .wrapper_menu .container_menu {
  max-width: 1020px;
  position: relative;
  width: 100%;
  margin: auto;
  transition: all .4s;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu {
    position: static;
  }
}

.container_app_header .wrapper_menu .container_menu::after {
  content: "";
  clear: both;
  display: table;
}

.container_app_header .wrapper_menu .container_menu .wrapper_logo {
  float: left;
  padding: 10px;
  transition: all .4s;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .wrapper_logo {
    padding: 5px;
  }
}

.container_app_header .wrapper_menu .container_menu .wrapper_logo img {
  height: 50px;
  transition: all .4s;
}

.container_app_header .wrapper_menu .container_menu .wrapper_icon_menu {
  position: absolute;
  right: 30px;
  top: 70px;
  display: none;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .wrapper_icon_menu {
    display: block;
  }
}

@media all and (max-width: 767.5px) {
  .container_app_header .wrapper_menu .container_menu .wrapper_icon_menu {
    top: 20px;
  }
}

.container_app_header .wrapper_menu .container_menu .menu {
  float: right;
  list-style-position: outside;
  list-style: none;
  padding: 20px 0 0 0;
  margin: 0;
  transition: all .4s;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu {
    background: #dddddd;
    position: absolute;
    padding: 0;
    width: 320px;
    left: 0;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    z-index: 100;
    float: none;
    display: none;
  }
}

.container_app_header .wrapper_menu .container_menu .menu.show_mobile {
  display: block;
}

.container_app_header .wrapper_menu .container_menu .menu .wrapper_header_menu {
  display: none;
  position: relative;
  padding: 0 20px;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu .wrapper_header_menu {
    display: block;
  }
}

.container_app_header .wrapper_menu .container_menu .menu .wrapper_header_menu .btn_close {
  background: darkred;
  position: absolute;
  z-index: 10;
  top: -8px;
  right: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.container_app_header .wrapper_menu .container_menu .menu > li {
  display: inline-block;
  margin-left: 10px;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li {
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: solid thin #b7b7b7;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li > a {
  display: block;
  color: black;
  text-decoration: none;
  transition: all .4s;
  padding: 10px 4px;
  text-transform: uppercase;
  font-size: 0.9em;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li > a {
    padding: 10px 20px;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li > a:hover {
  cursor: pointer;
  border-bottom: solid 4px dimgrey;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li > a:hover {
    border: none;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.active > a {
  color: darkred;
  border-bottom: solid 4px darkred;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.active > a {
    border: none;
  }
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu {
    position: relative;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu i {
  position: absolute;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  display: none;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu i {
    display: block;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu {
  display: none;
  background: #dfdfdf;
  padding: 30px 50px;
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0;
  transition: all .4s;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu {
    display: none;
    padding: 0;
    position: relative;
    background: #f2f2f2;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu {
  float: left;
  padding: 0 10px;
  margin-right: 90px;
  border-left: dotted thin darkred;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu {
    float: none;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu:after {
  content: ' ';
  display: block;
  clear: both;
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu .submenu_productos {
  list-style: none;
  padding: 0;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu .submenu_productos {
    float: none;
    border: none;
    margin: 0;
    padding-left: 30px;
  }
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu .submenu_productos li {
  padding: 6px 0;
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu .submenu_productos li a {
  color: darkred;
  text-decoration: none;
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu .cont_submenu .colum_submenu .submenu_productos li a:hover {
  color: black;
}

.container_app_header .wrapper_menu .container_menu .menu > li.item_submenu:hover .cont_submenu {
  display: block;
}

@media all and (max-width: 991.5px) {
  .container_app_header .wrapper_menu .container_menu .menu > li.item_submenu:hover .cont_submenu {
    display: none;
  }
}

.container_app_header .wrapper_menu.active {
  position: fixed;
  top: 0;
  z-index: 20;
  background: rgba(215, 215, 215, 0.96);
  margin: auto;
  width: 100%;
}

.container_app_header .wrapper_menu.active .wrapper_logo {
  padding: 10px 0 10px 30px;
}

.container_app_header .wrapper_menu.active .wrapper_logo img {
  height: 40px;
}

.container_app_header .wrapper_menu.active .wrapper_icon_menu {
  position: absolute;
  right: 30px;
  top: 25px;
}

.container_app {
  position: absolute;
  width: 100%;
}

.container_app .wrapper_pnl_info {
  background: rgba(217, 217, 217, 0.81);
  position: relative;
  border-top: solid thin #b8b8b8;
}

.container_app .wrapper_pnl_info .pnl_info {
  margin: auto;
  padding: 25px 0 25px 0;
  max-width: 1020px;
}

.container_app .wrapper_pnl_info .pnl_info > ul {
  list-style: none;
}

@media all and (max-width: 767.5px) {
  .container_app .wrapper_pnl_info .pnl_info > ul {
    padding: 0 20px;
  }
}

.container_app .wrapper_pnl_info .pnl_info > ul > li {
  float: left;
  margin-right: 30px;
}

@media all and (max-width: 991.5px) {
  .container_app .wrapper_pnl_info .pnl_info > ul > li {
    margin-top: 20px;
  }
}

.container_app .wrapper_pnl_info .pnl_info > ul > li .container_text {
  display: block;
}

.container_app .wrapper_pnl_info .pnl_info > ul > li .container_text > i {
  float: left;
  padding: 5px 18px;
  font-size: 40px;
  color: darkred;
}

.container_app .wrapper_pnl_info .pnl_info > ul > li .container_text span {
  float: left;
  color: #646464;
  font-size: 12px;
}

.container_app .wrapper_pnl_info .pnl_info > ul > li .container_text .text_one_line {
  padding: 20px 0;
}

.container_app .wrapper_pnl_info .pnl_info > ul > li .container_text:after {
  content: "";
  clear: both;
  display: table;
}

.container_app .wrapper_pnl_info .pnl_info:after {
  content: "";
  clear: both;
  display: table;
}

.container_app .text_copy_right {
  background: black;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
}

.carousel-showmanymoveone .carousel-control {
  width: 4%;
  background-image: none;
}

.carousel-showmanymoveone .carousel-control.left {
  margin-left: 15px;
}

.carousel-showmanymoveone .carousel-control.right {
  margin-right: 15px;
}

.carousel-showmanymoveone .cloneditem-1,
.carousel-showmanymoveone .cloneditem-2,
.carousel-showmanymoveone .cloneditem-3 {
  display: none;
}

@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .carousel-showmanymoveone .carousel-inner > .item.active.right,
  .carousel-showmanymoveone .carousel-inner > .item.next {
    transform: translate3d(50%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.active.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev {
    transform: translate3d(-50%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev.right,
  .carousel-showmanymoveone .carousel-inner > .item.active {
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media all and (min-width: 768px) {
  .carousel-showmanymoveone .carousel-inner > .active.left,
  .carousel-showmanymoveone .carousel-inner > .prev {
    left: -50%;
  }
  .carousel-showmanymoveone .carousel-inner > .active.right,
  .carousel-showmanymoveone .carousel-inner > .next {
    left: 50%;
  }
  .carousel-showmanymoveone .carousel-inner > .left,
  .carousel-showmanymoveone .carousel-inner > .prev.right,
  .carousel-showmanymoveone .carousel-inner > .active {
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner .cloneditem-1 {
    display: block;
  }
}

@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .carousel-showmanymoveone .carousel-inner > .item.active.right,
  .carousel-showmanymoveone .carousel-inner > .item.next {
    transform: translate3d(25%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.active.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev {
    transform: translate3d(-25%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev.right,
  .carousel-showmanymoveone .carousel-inner > .item.active {
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media all and (min-width: 992px) {
  .carousel-showmanymoveone .carousel-inner > .active.left,
  .carousel-showmanymoveone .carousel-inner > .prev {
    left: -25%;
  }
  .carousel-showmanymoveone .carousel-inner > .active.right,
  .carousel-showmanymoveone .carousel-inner > .next {
    left: 25%;
  }
  .carousel-showmanymoveone .carousel-inner > .left,
  .carousel-showmanymoveone .carousel-inner > .prev.right,
  .carousel-showmanymoveone .carousel-inner > .active {
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner .cloneditem-2,
  .carousel-showmanymoveone .carousel-inner .cloneditem-3 {
    display: block;
  }
}

.container_page_inicio {
  width: 100%;
}

.container_page_inicio .wrapper_slider {
  width: 100%;
}

.container_page_inicio .wrapper_slider .container_slider {
  margin: auto;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item {
    height: 305px !important;
  }
}

.container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item img {
  width: 100%;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item img {
    height: 100%;
  }
}

.container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item .caption_position_left {
  background: rgba(36, 36, 36, 0.78);
  right: initial;
  bottom: 0;
  left: 0;
  padding: 10px 30px;
  color: white;
  width: 100%;
}

.container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item .caption_position_left img {
  width: 250px;
}

.container_page_inicio .wrapper_slider .container_slider #carousel_landing .carousel-inner .item .caption_position_center {
  background: rgba(36, 36, 36, 0.78);
  right: initial;
  bottom: 45%;
  left: 50%;
  margin-left: -22.5%;
  padding: 30px;
  color: white;
}

.container_page_inicio .wrapper_pnl_nosotros {
  width: 100%;
  background: lightgrey;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros {
  max-width: 1020px;
  margin: auto;
  padding: 40px 50px;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros {
    padding: 40px 20px;
  }
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .row {
  margin: 0;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .titule {
  color: darkred;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list {
  max-width: 1660px;
  padding-top: 25px;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li {
  float: left;
  width: 33.3%;
  text-align: center;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li .container_info {
  border-right: dotted 1px darkred;
  height: 100%;
  padding: 5px 15px;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li .container_info {
    border: none;
  }
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li .container_info .container_img {
  width: 100px;
  height: 100px;
  margin: auto;
  background: white;
  border-radius: 70px;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li .container_info .container_img i {
  color: darkred;
  font-size: 40px;
  margin: 30px 0;
}

.container_page_inicio .wrapper_pnl_nosotros .pnl_nosotros .container_list .list_nosotros > li:last-child .container_info {
  border: none;
}

.container_page_inicio .cont_pnl_slogan {
  width: 100%;
  background: darkred;
}

.container_page_inicio .cont_pnl_slogan .wrapper_pnl_slogan {
  max-width: 1020px;
  text-align: center;
  padding: 50px 30px;
  margin: auto;
}

.container_page_inicio .cont_pnl_slogan .wrapper_pnl_slogan span {
  font-size: 22px;
  color: white;
}

.container_page_inicio .wrapper_pnl_brands {
  width: 100%;
}

.container_page_inicio .wrapper_pnl_brands .pnl_brands {
  background: white;
  margin: auto;
  padding: 30px 50px;
  max-width: 1020px;
}

.container_page_inicio .wrapper_pnl_brands .pnl_brands .titule {
  color: darkred;
}

.container_page_inicio .wrapper_pnl_brands .pnl_brands .container_carousel {
  padding-top: 30px;
}

.container_page_inicio .wrapper_pnl_brands .pnl_brands .container_carousel .item {
  text-align: center;
}

.container_page_inicio .wrapper_pnl_brands .pnl_brands .container_carousel .item img {
  height: 60px;
}

.container_page_inicio .wrapper_pnl_productos {
  width: 100%;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos {
  max-width: 1020px;
  background: white;
  margin: auto;
  padding: 30px 0;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .titule {
  color: darkred;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul {
  list-style: none;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul {
    padding: 20px;
  }
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
  text-align: center;
  background: whitesmoke;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  width: 23%;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media all and (max-width: 991.5px) {
  .container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
    width: 31%;
    margin-right: 7px;
  }
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
    width: 100%;
  }
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item img {
  height: 200px;
  transition: all 2s ease;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item .content_item {
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  transition: all .5s ease;
  color: white;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item .content_item .wrapper_titule {
  background: darkred;
  padding: 10px 3px;
  text-transform: uppercase;
  font-size: 13px;
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container_page_inicio .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item:hover .content_item {
  opacity: 1;
  height: 100%;
}

.container_page_inicio .wrapper_pnl_servicios {
  width: 100%;
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios {
  background: white;
  margin: auto;
  padding: 30px 0;
  max-width: 1020px;
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .titule {
  color: darkred;
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul {
  list-style: none;
  text-align: center;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul {
    padding: 25px;
  }
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item {
  float: left;
  width: 30%;
  margin: 0 1%;
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel {
  background: #f8f8f8;
  padding: 25px 15px;
  border: solid thin #e5e5e5;
  border-radius: 3px;
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel .wrapper_titule {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel .wrapper_texto {
  line-height: 25px;
  margin-bottom: 25px;
}

@media all and (max-width: 991.5px) {
  .container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel .wrapper_texto {
    min-height: 200px;
  }
}

@media all and (max-width: 767.5px) {
  .container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel .wrapper_texto {
    min-height: inherit;
  }
}

.container_page_inicio .wrapper_pnl_servicios .pnl_servicios .wrapper_list ul .item .container_panel .btn_leer {
  background: darkred;
  padding: 15px 40px;
  display: inline-block;
  color: white;
  text-decoration: none;
}

.container_nosotros {
  width: 100%;
}

.container_nosotros .titule {
  color: darkred;
}

.container_nosotros .parallax_nosotros {
  background: url("../../../../../storage/app/media/nosotros/parallax_nosotros_1.jpg");
  height: 400px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.container_nosotros .container_pnl_nosotros {
  width: 100%;
}

.container_nosotros .container_pnl_nosotros .pnl_cabecera {
  max-width: 1020px;
  background: white;
  margin: auto;
}

@media all and (max-width: 767.5px) {
  .container_nosotros .container_pnl_nosotros .pnl_cabecera {
    padding: 0 20px;
  }
}

.container_nosotros .container_pnl_nosotros .pnl_cabecera .row {
  margin: 0;
}

.container_nosotros .container_pnl_nosotros .pnl_cabecera .text_titule {
  margin: 40px 0 30px 0;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros {
  max-width: 1020px;
  background: white;
  margin: auto;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .row {
  margin: 0;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .wrapper_text {
  padding: 20px 30px;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .cont_img {
  padding: 40px 30px;
  text-align: center;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .cont_img img {
  height: 480px;
}

@media all and (max-width: 767.5px) {
  .container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .cont_img img {
    width: 100%;
    height: auto;
  }
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .wrapper_articulos h3 {
  text-align: center;
  color: darkred;
}

.container_nosotros .container_pnl_nosotros .wrapper_pnl_nosotros .wrapper_articulos img {
  width: 100%;
}

.container_nosotros .container_pnl_valores {
  width: 100%;
  background: lightgrey;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores {
  max-width: 1020px;
  margin: auto;
  padding: 60px 20px;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .row {
  margin: 0;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores {
  margin: 0;
  list-style: none;
}

@media all and (max-width: 767.5px) {
  .container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores {
    padding: 0 20px;
  }
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li {
  float: left;
  width: 30%;
  margin-right: 3%;
  height: 380px;
  text-align: center;
}

@media all and (max-width: 991.5px) {
  .container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li {
    height: 420px;
  }
}

@media all and (max-width: 767.5px) {
  .container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li h3 {
  text-transform: uppercase;
  line-height: 30px;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li .container_img {
  width: 100px;
  height: 100px;
  background: white;
  margin: 20px auto;
  border-radius: 50px;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li .container_img i {
  font-size: 40px;
  color: darkred;
  padding: 30px 0;
}

.container_nosotros .container_pnl_valores .wrapper_pnl_valores .list_valores > li strong {
  font-size: 16px;
  color: darkred;
}

.container_nosotros .container_pnl_proveedores {
  width: 100%;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores {
  max-width: 1020px;
  margin: auto;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .row {
  margin: 0;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .wrapper_text {
  padding: 20px 30px;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .cont_img {
  padding: 40px 30px;
  text-align: center;
}

@media all and (max-width: 767.5px) {
  .container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .cont_img img {
    width: 100%;
  }
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .container_carousel {
  padding: 50px 0;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .container_carousel .item {
  text-align: center;
}

.container_nosotros .container_pnl_proveedores .wrapper_pnl_proveedores .container_carousel .item img {
  height: 60px;
}

.container_page_productos {
  width: 100%;
  background: white;
}

.container_page_productos .wrapper_content {
  max-width: 1020px;
  background: white;
  margin: auto;
}

.container_page_productos .wrapper_content .row {
  margin: 0;
}

.container_page_productos .wrapper_content .list_products {
  margin: 50px 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

@media all and (max-width: 767.5px) {
  .container_page_productos .wrapper_content .list_products {
    padding: 0 20px;
    margin: 40px 0 0 0;
  }
}

.container_page_productos .wrapper_content .list_products li {
  float: left;
  width: 32%;
  margin-left: 1%;
}

@media all and (max-width: 767.5px) {
  .container_page_productos .wrapper_content .list_products li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.container_page_productos .wrapper_content .list_products li .pnl_product {
  position: relative;
  display: block;
  cursor: pointer;
  transition: all .4s;
}

.container_page_productos .wrapper_content .list_products li .pnl_product:hover {
  box-shadow: 5px 5px 12px gray;
}

.container_page_productos .wrapper_content .list_products li .pnl_product img {
  width: 100%;
}

.container_page_productos .wrapper_content .list_products li .pnl_product .wrapper_titule {
  background: darkred;
  color: white;
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  padding: 15px 0;
  font-size: 16px;
}

.container_page_productos .wrapper_content .list_products:after {
  content: ' ';
  display: block;
  clear: both;
}

.container_page_productos .wrapper_content .wrapper_text {
  line-height: 25px;
  padding: 0 20px;
}

.container_page_productos .wrapper_content .wrapper_text p {
  margin-bottom: 25px;
}

.container_page_productos .wrapper_content .wrapper_text .titule {
  color: darkred;
}

.container_page_productos_1 {
  width: 100%;
  background: white;
}

.container_page_productos_1::after {
  content: '';
  display: block;
  clear: both;
}

.container_page_productos_1 .pnl_asc_con_maq {
  margin: auto;
  max-width: 1600px;
  border-bottom: solid thin lightgrey;
}

.container_page_productos_1 .pnl_asc_con_maq::after {
  content: '';
  display: block;
  clear: both;
}

.container_page_productos_1 .pnl_asc_con_maq .text {
  max-width: 800px;
  width: 60%;
  float: left;
  padding: 150px 80px;
  line-height: 23px;
  position: relative;
  font-size: 14px;
}

.container_page_productos_1 .pnl_asc_con_maq .text .cont_titule {
  background: darkred;
  margin-bottom: 40px;
  padding-left: 15px;
}

.container_page_productos_1 .pnl_asc_con_maq .text .cont_titule .titule {
  color: darkred;
  background: white;
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
}

.container_page_productos_1 .pnl_asc_con_maq .wrapper_image {
  float: left;
  width: 40%;
  text-align: center;
  padding: 60px;
  background: #f6f6f6;
}

.container_page_productos_1 .pnl_asc_con_maq .wrapper_image img {
  height: 400px;
}

.container_page_productos_1 .pnl_asc_con_maq .pnl_con_image {
  padding: 0;
}

.container_page_productos_1 .pnl_asc_con_maq .pnl_con_image img {
  width: 100%;
  height: 100%;
}

.container_ref {
  width: 100%;
}

.container_ref .titule {
  color: darkred;
  margin-bottom: 30px;
}

.container_ref .wrapper_list {
  max-width: 1020px;
  margin: auto;
  width: 90%;
  padding: 20px;
}

.container_ref .wrapper_list .list_referencias {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container_ref .wrapper_list .list_referencias:after {
  content: ' ';
  display: block;
  clear: both;
}

.container_ref .wrapper_list .list_referencias li {
  float: left;
  width: 25%;
  padding: 5px;
}

@media all and (max-width: 991.5px) {
  .container_ref .wrapper_list .list_referencias li {
    width: 33.3%;
  }
}

@media all and (max-width: 767.5px) {
  .container_ref .wrapper_list .list_referencias li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.container_ref .wrapper_list .list_referencias li:first-child {
  margin-left: 0;
}

.container_ref .wrapper_list .list_referencias li .cont_item {
  position: relative;
}

.container_ref .wrapper_list .list_referencias li .cont_item img {
  width: 100%;
}

.container_ref .wrapper_list .list_referencias li .cont_item .wrapper_text {
  background: rgba(0, 0, 0, 0.41);
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  transition: all .5s;
}

.container_ref .wrapper_list .list_referencias li .cont_item .wrapper_text .titule {
  text-align: center;
  color: white;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  transition: all .5s;
}

.container_ref .wrapper_list .list_referencias li:hover {
  box-shadow: 2px 2px 12px black;
}

.container_ref .wrapper_list .list_referencias li:hover .wrapper_text {
  opacity: 0;
}

.container_ref .wrapper_list .list_referencias li:hover .wrapper_text .titule {
  top: 23%;
}

.container_servicios {
  width: 100%;
}

.container_servicios .parallax_servicios {
  background: url("../../../../../storage/app/media/nosotros/parallax_nosotros.jpg");
  height: 400px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.container_servicios .pnl_cabecera {
  max-width: 1020px;
  background: white;
  margin: auto;
  margin-bottom: 50px;
}

@media all and (max-width: 767.5px) {
  .container_servicios .pnl_cabecera {
    padding: 0 20px;
  }
}

.container_servicios .pnl_cabecera .row {
  margin: 0;
}

.container_servicios .pnl_cabecera .text_titule {
  margin: 40px 0 30px 0;
}

.container_servicios .pnl_cabecera .text_titule .titule {
  color: darkred;
}

.container_servicios .container_panels {
  width: 100%;
}

.container_servicios .container_panels .wrapper_panels {
  max-width: 1020px;
  margin: auto;
  padding: 30px;
}

.container_servicios .container_panels .wrapper_panels .panel {
  position: relative;
  padding: 70px 50px 20px 50px;
  margin-bottom: 60px;
  text-align: center;
  box-shadow: 2px 2px 17px #bfbfbf;
}

@media all and (max-width: 767.5px) {
  .container_servicios .container_panels .wrapper_panels .panel {
    padding: 70px 15px 20px 15px;
  }
}

.container_servicios .container_panels .wrapper_panels .panel .wrapper_icon {
  width: 120px;
  height: 60px;
  position: absolute;
  background: darkred;
  top: -20px;
  left: 50%;
  margin-left: -60px;
}

.container_servicios .container_panels .wrapper_panels .panel .wrapper_icon::before {
  content: ' ';
  position: absolute;
  bottom: -15px;
  right: 50%;
  margin-right: -15px;
  border-left: 15px solid white;
  border-right: 15px solid white;
  border-top: 15px solid #8b0000;
  width: 0;
  height: 0;
}

.container_servicios .container_panels .wrapper_panels .panel .wrapper_icon i {
  padding: 10px 0;
  font-size: 40px;
  color: white;
}

.container_servicios .container_panels .wrapper_panels .panel .titule {
  font-size: 20px;
  color: darkred;
  margin-bottom: 20px;
}

.container_servicios .container_panels .wrapper_panels .panel .wrapper_text {
  line-height: 24px;
  text-align: left;
}

.container_servicios .container_panels .wrapper_panels .panel .wrapper_text p {
  margin-bottom: 20px;
}

.container_servicios .container_panels .wrapper_panels .panel.pnl_modern {
  height: 892px;
}

@media all and (max-width: 991.5px) {
  .container_servicios .container_panels .wrapper_panels .panel.pnl_modern {
    height: inherit;
  }
}

.container_contacts .row {
  margin: 0;
}

.container_contacts .wrapper_titule {
  margin: 40px 0 10px 0;
}

.container_contacts .wrapper_titule .titule {
  color: darkred;
}

.container_contacts .pnl_contactos {
  max-width: 100%;
  margin-bottom: 50px;
}

.container_contacts .pnl_contactos::after {
  content: ' ';
  display: block;
  clear: both;
}

.container_contacts .pnl_contactos .wrapper_contactos {
  width: 900px;
  margin: auto;
}

@media all and (max-width: 991.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos {
    width: 100%;
    padding: 0 20px;
  }
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_titule {
  text-align: center;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_titule .titule {
  font-size: 20px;
  color: darkred;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li {
  width: 48%;
  height: 185px;
  background: #f2f2f2;
  float: left;
  margin: 1%;
  padding: 8px 10px;
  border: solid thin lightgray;
}

@media all and (max-width: 767.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li {
    width: 220px;
    display: block;
    height: auto;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    float: none;
  }
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li img {
  width: 150px;
  float: left;
}

@media all and (max-width: 991.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li img {
    width: 145px;
  }
}

@media all and (max-width: 767.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li img {
    float: none;
    display: block;
    width: 120px;
    margin: auto;
  }
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info {
  padding: 17px;
  padding-left: 160px;
  font-size: 16px;
}

@media all and (max-width: 991.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info {
    padding: 25px 0 0 155px;
  }
}

@media all and (max-width: 767.5px) {
  .container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info {
    padding: 5px;
    font-size: 16px;
    width: 100%;
    display: block;
  }
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info span {
  display: block;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info span.titule {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info span.email {
  font-size: 12px;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info a {
  color: black;
  padding: 10px 0 10px 0;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li .cont_info a i {
  color: darkred;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li.tipo_2 {
  text-align: center;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li.tipo_2 img {
  float: none;
  width: 75%;
  display: block;
  margin: auto;
  padding: 10px 0;
}

.container_contacts .pnl_contactos .wrapper_contactos .wrapper_list .list_contacts li.tipo_2 .cont_info {
  text-align: center;
  padding: 10px 0;
}

.container_contacts .cont_data_contacts {
  width: 100%;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts {
  max-width: 1020px;
  margin: auto;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details {
  padding: 40px 50px;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details {
    padding: 0;
  }
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details .titule {
  font-size: 20px;
  color: darkred;
  margin-left: 65px;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details .titule {
    margin: 0;
    display: block;
    text-align: center;
  }
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul {
  list-style: none;
  margin-top: 25px;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul {
    width: 100%;
    padding: 0 20px;
  }
}

@media all and (max-width: 767.5px) {
  .container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul {
    padding: 0;
  }
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li {
  margin-bottom: 25px;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text {
  display: block;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text > i {
  float: left;
  padding: 20px 20px;
  font-size: 24px;
  color: darkred;
}

@media all and (max-width: 767.5px) {
  .container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text > i {
    padding: 20px 15px;
  }
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text span {
  float: left;
  color: #646464;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text .text_one_line {
  padding: 20px 0;
}

.container_contacts .cont_data_contacts .wrapper_data_contacts .wrapper_list_details > ul > li .container_text:after {
  content: "";
  clear: both;
  display: table;
}

.container_contacts .cont_form {
  border-left: dashed lightgrey;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_form {
    border: none;
  }
}

.container_contacts .cont_form .wrapper_form {
  padding: 40px 30px;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_form .wrapper_form {
    padding: 40px 20px;
  }
}

.container_contacts .cont_form .wrapper_form .form .titule {
  font-size: 20px;
  color: darkred;
}

@media all and (max-width: 991.5px) {
  .container_contacts .cont_form .wrapper_form .form .titule {
    display: block;
    text-align: center;
  }
}

.container_contacts .cont_form .wrapper_form .form ul {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}

.container_contacts .cont_form .wrapper_form .form ul li {
  margin-bottom: 15px;
}

.container_contacts .cont_form .wrapper_form .form ul li label {
  display: block;
}

.container_contacts .cont_form .wrapper_form .form ul li input, .container_contacts .cont_form .wrapper_form .form ul li textarea {
  display: block;
  width: 100%;
  border: solid thin lightgray;
  padding: 8px;
  outline-color: lightgrey;
  outline-style: solid;
  outline-width: 1px;
}

.container_contacts .cont_form .wrapper_form .form ul li #enviar_consulta {
  background: darkred;
  width: 200px;
  color: white;
  border: none;
  padding: 10px;
  transition: background .4s;
}

.container_contacts .cont_form .wrapper_form .form ul li #enviar_consulta:hover {
  background: #7c0505;
}

.container_contacts .cont_maps_google {
  width: 100%;
}

.cont_carrera {
  width: 100%;
}

.cont_carrera .wrapper_slider {
  margin: auto;
}

.cont_carrera .wrapper_slider .carousel-inner .item img {
  width: 100%;
}

.cont_carrera .wrapper_slider .carousel-inner .item .carousel-caption {
  background: rgba(36, 36, 36, 0.78);
  right: initial;
  bottom: 0;
  left: 50%;
  color: white;
  width: 55%;
  height: 100%;
  text-align: left;
}

@media all and (max-width: 767.5px) {
  .cont_carrera .wrapper_slider .carousel-inner .item .carousel-caption {
    display: none;
  }
}

.cont_carrera .wrapper_slider .carousel-inner .item .carousel-caption h3 {
  font-size: 36px;
  line-height: 43px;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 120px 0 30px;
  font-weight: 100;
}

@media all and (max-width: 991.5px) {
  .cont_carrera .wrapper_slider .carousel-inner .item .carousel-caption h3 {
    font-size: 25px;
    line-height: 30px;
  }
}

.cont_carrera .wrapper_text {
  max-width: 1020px;
  margin: auto;
  text-align: justify;
  line-height: 26px;
}

@media all and (max-width: 767.5px) {
  .cont_carrera .wrapper_text {
    padding: 0 20px;
  }
}

.cont_carrera .wrapper_text .row {
  margin: 0;
}

.cont_carrera .wrapper_text .titule {
  color: darkred;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
}

.cont_carrera .wrapper_text .subtitule {
  color: darkred;
}

.cont_carrera .wrapper_text .text_otro {
  font-size: 18px;
  font-weight: bold;
}

.cont_carrera .wrapper_principios {
  max-width: 1020px;
  margin: auto;
  text-align: justify;
  line-height: 26px;
}

@media all and (max-width: 767.5px) {
  .cont_carrera .wrapper_principios {
    padding: 0 20px;
  }
}

.cont_carrera .wrapper_principios .row {
  margin: 0;
}

.cont_carrera .wrapper_principios .wrapper_foto {
  background: #e9e9e9;
  text-align: center;
  padding: 15px;
}

.cont_carrera .wrapper_principios .titule {
  color: darkred;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
}

.cont_carrera .wrapper_principios ul {
  list-style: none;
  line-height: 23px;
  padding: 0;
}

.container_page_prod_ascensores {
  width: 100%;
}

.container_page_prod_ascensores .parallax_prod_ascensores {
  background: url("../../../../../storage/app/media/productos/main/ascensores_header.jpg");
  height: 500px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.container_page_prod_ascensores .parallax_prod_ascensores .caption {
  position: absolute;
  background: rgba(36, 36, 36, 0.78);
  width: 100%;
  bottom: 0;
  color: white;
  padding: 20px 30px;
  font-size: 25px;
}

.container_page_prod_ascensores .container_text {
  max-width: 1020px;
  background: white;
  margin: auto;
  padding: 20px 15px;
  line-height: 26px;
}

.container_page_prod_ascensores .container_text .row {
  margin: 0;
}

.container_page_prod_ascensores .container_list .row {
  margin: 0;
}

.container_page_prod_ascensores .container_list .wrapper_text_titule {
  max-width: 1020px;
  margin: auto;
  padding: 20px;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos {
  width: 100%;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos {
  max-width: 1020px;
  background: white;
  margin: auto;
  padding: 0 0 30px 0;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .titule {
  color: darkred;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul {
  list-style: none;
}

@media all and (max-width: 767.5px) {
  .container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul {
    padding: 20px;
  }
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
  text-align: center;
  background: whitesmoke;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  width: 23%;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media all and (max-width: 991.5px) {
  .container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
    width: 31%;
    margin-right: 7px;
  }
}

@media all and (max-width: 767.5px) {
  .container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item {
    width: 100%;
  }
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item img {
  height: 200px;
  transition: all 2s ease;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item .content_item {
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  transition: all .5s ease;
  color: white;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item .content_item .wrapper_titule {
  background: darkred;
  padding: 10px 3px;
  text-transform: uppercase;
  font-size: 13px;
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container_page_prod_ascensores .container_list .wrapper_pnl_productos .pnl_productos .wrapper_list ul .item:hover .content_item {
  opacity: 1;
  height: 100%;
}

.cont_detail_product {
  width: 100%;
  background: white;
}

.cont_detail_product::after {
  content: '';
  display: block;
  clear: both;
}

.cont_detail_product .pnl_product {
  margin: 60px auto;
  max-width: 1020px;
}

@media all and (max-width: 991.5px) {
  .cont_detail_product .pnl_product {
    margin: 0;
  }
}

.cont_detail_product .pnl_product::after {
  content: '';
  display: block;
  clear: both;
}

.cont_detail_product .pnl_product .text {
  max-width: 800px;
  width: 60%;
  float: left;
  padding: 0 25px;
  line-height: 23px;
  position: relative;
  font-size: 16px;
}

@media all and (max-width: 991.5px) {
  .cont_detail_product .pnl_product .text {
    width: 100% !important;
    margin-top: 30px;
    padding: 0 20px;
  }
}

.cont_detail_product .pnl_product .text .cont_titule {
  background: darkred;
  margin-bottom: 15px;
  padding-left: 15px;
}

.cont_detail_product .pnl_product .text .cont_titule .wrapper_titule {
  background: darkred;
  padding: 10px;
  display: inline-block;
}

.cont_detail_product .pnl_product .text .cont_titule .wrapper_titule .titule {
  color: white;
  font-size: 20px;
  padding: 5px 5px;
}

.cont_detail_product .pnl_product .wrapper_image {
  float: left;
  width: 40%;
  text-align: center;
  padding: 60px;
  background: #f6f6f6;
}

@media all and (max-width: 991.5px) {
  .cont_detail_product .pnl_product .wrapper_image {
    width: 100% !important;
  }
}

.cont_detail_product .pnl_product .wrapper_image img {
  height: 400px;
}

.cont_detail_product .pnl_product .pnl_con_image {
  padding: 0;
}

.cont_detail_product .pnl_product .pnl_con_image img {
  width: 100%;
  height: 100%;
}

.container_job {
  width: 100%;
}

.container_job .row {
  margin: 0;
}

.container_job .wrapper_job {
  max-width: 1020px;
  margin: auto;
  padding: 50px 20px;
  line-height: 24px;
  text-align: justify;
}

.container_job .wrapper_job .btn_back {
  display: inline-block;
  position: relative;
  left: 50%;
  margin-left: -12.5%;
  margin-top: 50px;
}

.container_job .wrapper_job .btn_back a {
  border: solid 2px darkred;
  color: darkred;
  text-decoration: none;
  padding: 10px 50px;
  display: block;
  transition: all .4s;
}

.container_job .wrapper_job .btn_back a i {
  padding-right: 5px;
}

.container_job .wrapper_job .btn_back a:hover {
  background: darkred;
  color: whitesmoke;
}

.container_job .wrapper_job .titule {
  color: darkred;
  text-align: center;
  margin-bottom: 30px;
}

.container_job .wrapper_job .referencia {
  background: #ededed;
  display: inline-block;
  padding: 15px;
}

.container_job .wrapper_job .referencia span {
  display: block;
}

.container_job .wrapper_job .referencia span:first-child {
  font-weight: bold;
}

.conta_puesto_trabajo {
  width: 100%;
}

.conta_puesto_trabajo .row {
  margin: 0;
}

.conta_puesto_trabajo .parallax_puestos_trabajo {
  background: url("../../../../../storage/app/media/carrera_profesional/puesto_de_trabajo/parallax_puesto_trabajo.jpg");
  height: 400px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.conta_puesto_trabajo .wrapper_text {
  max-width: 1020px;
  margin: auto;
  text-align: justify;
  line-height: 26px;
}

@media all and (max-width: 767.5px) {
  .conta_puesto_trabajo .wrapper_text {
    padding: 0 20px;
  }
}

.conta_puesto_trabajo .wrapper_text .row {
  margin: 0;
}

.conta_puesto_trabajo .wrapper_text .titule {
  color: darkred;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
}

.conta_puesto_trabajo .wrapper_text .subtitule {
  color: darkred;
}

.conta_puesto_trabajo .wrapper_text .text_otro {
  font-size: 18px;
  font-weight: bold;
}

.conta_puesto_trabajo .wrapper_table {
  max-width: 1020px;
  margin: auto;
  padding: 20px 30px;
}

@media all and (max-width: 767.5px) {
  .conta_puesto_trabajo .wrapper_table {
    padding: 0;
  }
}

.conta_puesto_trabajo .wrapper_table .table_jobs {
  width: 100%;
}

.conta_puesto_trabajo .wrapper_table .table_jobs tr:nth-child(odd) {
  background: #ededed;
}

.conta_puesto_trabajo .wrapper_table .table_jobs tr:nth-child(even) {
  background: lightgrey;
}

.conta_puesto_trabajo .wrapper_table .table_jobs tr.header {
  background: darkred;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.conta_puesto_trabajo .wrapper_table .table_jobs td {
  padding: 15px;
  font-size: 16px;
}

@media all and (max-width: 767.5px) {
  .conta_puesto_trabajo .wrapper_table .table_jobs td {
    padding: 10px 12px;
  }
}

.conta_puesto_trabajo .wrapper_table .table_jobs td a {
  color: black;
}

.conta_puesto_trabajo .wrapper_table .table_jobs td:nth-child(2), .conta_puesto_trabajo .wrapper_table .table_jobs td:nth-child(3) {
  text-align: center;
}

.cont_graduados {
  width: 100%;
}

.cont_graduados .parallax_graduados {
  background: url("../../../../../storage/app/media/carrera_profesional/graduados/parallax_graduados.jpg");
  height: 400px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.cont_graduados .wrapper_graduados {
  max-width: 1020px;
  margin: auto;
  line-height: 25px;
  padding: 15px;
}

@media all and (max-width: 767.5px) {
  .cont_graduados .wrapper_graduados {
    padding: 0 20px;
  }
}

.cont_graduados .wrapper_graduados .row {
  margin: 0;
}

.cont_graduados .wrapper_graduados .titule {
  color: darkred;
  text-align: center;
  margin-bottom: 30px;
}

.cont_pasantia {
  width: 100%;
}

.cont_pasantia .parallax_pasantia {
  background: url("../../../../../storage/app/media/carrera_profesional/pasantia/parallax_pasantia.jpg");
  height: 400px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.cont_pasantia .wrapper_pasantia {
  max-width: 1020px;
  margin: auto;
  line-height: 25px;
  padding: 15px;
}

@media all and (max-width: 767.5px) {
  .cont_pasantia .wrapper_pasantia {
    padding: 15px 20px;
  }
}

.cont_pasantia .wrapper_pasantia .row {
  margin: 0;
}

.cont_pasantia .wrapper_pasantia .titule {
  color: darkred;
  text-align: center;
  margin-bottom: 30px;
}

.cont_pasantia .wrapper_pasantia .titule_1 {
  color: darkred;
}

.cont_pasantia .wrapper_pasantia .pnl_info {
  background: #ededed;
  padding: 15px;
}

@media all and (max-width: 767.5px) {
  .cont_pasantia .wrapper_pasantia .pnl_info {
    padding: 0;
    margin: 10px -15px;
  }
}

.cont_pasantia .wrapper_pasantia .pnl_info .pnl_titule {
  font-weight: bold;
}
