.container_nosotros{
  width: 100%;

  .titule{
    color: darkred;
  }

  .parallax_nosotros{
    background: url('../../../../../storage/app/media/nosotros/parallax_nosotros_1.jpg');
    height: 400px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .container_pnl_nosotros{
    width: 100%;

    .pnl_cabecera{
      max-width: $size_container_web;
      background: white;
      margin: auto;
      @include style_mobile{
        padding: 0 20px;
      }

      .row{
        margin: 0;
      }

      .text_titule{
        margin: 40px 0 30px 0;
      }

    }

    .wrapper_pnl_nosotros{
      max-width: $size_container_web;
      background: white;
      margin: auto;

      .row{
        margin: 0;
      }

      .wrapper_text{
        padding: 20px 30px;
      }

      .cont_img{
        padding: 40px 30px;
        text-align: center;
        img{
          height: 480px;
          @include style_mobile{
            width: 100%;
            height: auto;
          }
        }
      }

      .wrapper_articulos{

        h3{
          text-align: center;
          color: darkred;
        }

        img{
          width: 100%;
        }
      }

    }
  }

  .container_pnl_valores{
    width: 100%;
    background: lightgrey;

    .wrapper_pnl_valores{
      max-width: $size_container_web;
      margin: auto;
      padding: 60px 20px;

      .row{
        margin: 0;
      }

      .list_valores{
        margin: 0;
        list-style: none;
        @include style_mobile{
          padding: 0 20px;
        }

        >li{
          float: left;
          width: 30%;
          margin-right: 3%;
          height: 380px;
          text-align: center;

          @include style_tablet{
            height: 420px;
          }

          @include style_mobile{
            width: 100%;
            height: auto;
            margin-bottom: 30px;
          }

          h3{
            text-transform: uppercase;
            line-height: 30px;
          }

          .container_img{
            width: 100px;
            height: 100px;
            background: white;
            margin: 20px auto;
            border-radius: 50px;

            i{
              font-size: 40px;
              color: darkred;
              padding: 30px 0;
            }
          }

          strong{
            font-size: 16px;
            color: darkred;
          }

        }

      }

    }
  }


  .container_pnl_proveedores{
    width: 100%;

    .wrapper_pnl_proveedores{
      max-width: $size_container_web;
      margin: auto;

      .row{
        margin: 0;
      }

      .wrapper_text{
        padding: 20px 30px;
      }

      .cont_img{
        padding: 40px 30px;
        text-align: center;
        img{
          @include style_mobile{
            width: 100%;
          }
        }
      }

      .container_carousel {
        padding: 50px 0;

        .item {
          text-align: center;

          img {
            height: 60px;
          }
        }
      }

    }
  }



}