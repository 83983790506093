.cont_carrera{
  width: 100%;
  .wrapper_slider{
    margin: auto;

    .carousel-inner {
      .item {
        img {
          width: 100%;
        }

        .carousel-caption {
          background: rgba(36, 36, 36, 0.78);
          right: initial;
          bottom: 0;
          left: 50%;
          color: white;
          width: 55%;
          height: 100%;
          text-align: left;
          @include style_mobile{
            display: none;
          }
          h3{
            font-size: 36px;
            line-height: 43px;
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 120px 0 30px;
            font-weight: 100;

            @include style_tablet{
              font-size: 25px;
              line-height: 30px;
            }

          }
        }

      }
    }

  }

  .wrapper_text{
    max-width: $size_container_web;
    margin: auto;
    text-align: justify;
    line-height: 26px;
    @include style_mobile{
      padding: 0 20px;
    }


    .row{
      margin: 0;
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-top: 50px;
      text-transform: uppercase;
    }

    .subtitule{
      color: darkred;
    }

    .text_otro{
      font-size: 18px;
      font-weight: bold;
    }

  }

  .wrapper_principios{
    max-width: $size_container_web;
    margin: auto;
    text-align: justify;
    line-height: 26px;
    @include style_mobile{
      padding: 0 20px;
    }

    .row{
      margin: 0;
    }

    .wrapper_foto{
      background: #e9e9e9;
      text-align: center;
      padding: 15px;
    }

    .titule{
      color: darkred;
      text-align: center;
      margin-top: 50px;
      text-transform: uppercase;
    }

    ul{
      list-style: none;
      line-height: 23px;
      padding: 0;
    }

  }


}