.container_page_productos{
  width: 100%;
  background: white;

  .wrapper_content{
    max-width: $size_container_web;
    background: white;
    margin: auto;

    .row{
      margin: 0;
    }

    .list_products{
      margin: 50px 0;
      padding: 0;
      text-align: center;
      list-style: none;
      @include style_mobile{
        padding: 0 20px;
        margin: 40px 0 0 0;
      }

      li{
        float: left;
        width: 32%;
        margin-left: 1%;
        @include style_mobile{
          width: 100%;
          margin-bottom: 30px;
        }

        .pnl_product{
          position: relative;
          display: block;
          cursor: pointer;
          transition: all .4s;

          &:hover{
            box-shadow: 5px 5px 12px gray;
          }

          img{
            width: 100%;
          }

          .wrapper_titule{
            background: darkred;
            color: white;
            position: absolute;
            display: block;
            width: 100%;
            top: 0;
            padding: 15px 0;
            font-size: 16px;
          }

        }
      }

      &:after{
        content: ' ';
        display: block;
        clear: both;
      }

    }

    .wrapper_text{
      line-height: 25px;
      padding: 0 20px;
      p{
        margin-bottom: 25px;
      }

      .titule{
        color: darkred;
      }

    }

  }

}