.container_servicios {
  width: 100%;

  .parallax_servicios {
    background: url('../../../../../storage/app/media/nosotros/parallax_nosotros.jpg');
    height: 400px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .pnl_cabecera{
    max-width: $size_container_web;
    background: white;
    margin: auto;
    margin-bottom: 50px;

    @include style_mobile{
      padding: 0 20px;
    }

    .row{
      margin: 0;
    }

    .text_titule{
      margin: 40px 0 30px 0;

      .titule{
        color: darkred;
      }
    }

  }

  .container_panels{

    width: 100%;

    .wrapper_panels{
      max-width: $size_container_web;
      margin: auto;
      padding: 30px;
      .panel{
        position: relative;
        padding: 70px 50px 20px 50px;
        margin-bottom: 60px;
        text-align: center;
        box-shadow: 2px 2px 17px #bfbfbf;
        @include style_mobile{
          padding: 70px 15px 20px 15px;
        }

        .wrapper_icon{
          width: 120px;
          height: 60px;
          position: absolute;
          background: darkred;
          top: -20px;
          left: 50%;
          margin-left: -60px;

          &::before{
            content: ' ';
            position: absolute;
            bottom: -15px;
            right: 50%;
            margin-right: -15px;
            border-left: 15px solid white;
            border-right: 15px solid rgb(255, 255, 255);
            border-top: 15px solid #8b0000;
            width: 0;
            height: 0;
          }

          i{
            padding: 10px 0;
            font-size: 40px;
            color: white;
          }

        }

        .titule{
          font-size: 20px;
          color: darkred;
          margin-bottom: 20px;
        }

        .wrapper_text{
          line-height: 24px;
          text-align: left;
          p{
            margin-bottom: 20px;
          }
        }

        &.pnl_modern{
          height: 892px;
          @include style_tablet{
            height: inherit;
          }
        }

      }
    }

  }

}