
.container_app{
  position: absolute;
  width: 100%;

  .wrapper_pnl_info{
    background: rgba(217, 217, 217, 0.81);
    position: relative;
    border-top: solid thin #b8b8b8;

    .pnl_info{
      margin: auto;
      padding: 25px 0 25px 0;
      max-width: $size_container_web;;
      > ul{
        list-style: none;
        @include style_mobile{
          padding: 0 20px;
        }

        > li {
          float: left;
          margin-right: 30px;
          @include style_tablet{
            margin-top: 20px;
          }

          .container_text{
            display: block;

            > i {
              float: left;
              padding: 5px 18px;
              font-size: 40px;
              color: darkred;
            }

            span{
              float: left;
              color: #646464;
              font-size: 12px;
            }

            .text_one_line{
              padding: 20px 0 ;
            }

            &:after {
              content: "";
              clear: both;
              display: table;
            }

          }

        }
      }

      &:after {
        content: "";
        clear: both;
        display: table;
      }

    }
  }

    .text_copy_right{
      background: black;
      color: white;
      font-size: 12px;
      text-align: center;
      padding: 10px 0;
    }



}